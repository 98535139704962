<template>
  <el-menu
    :default-active="currentRoute"
    @select="handleSelect"
  >
    <el-menu-item v-show="!item.meta.isHidden" v-for="item in menu" :index="item.path" :key="item.path" :route="item.path">
      <el-icon component="Edit">
        <component :is="item.meta.icon" />
      </el-icon>
      <span>{{ item.meta.title }}</span>
    </el-menu-item>
  </el-menu>
</template>

<script setup>
import { computed, ref } from 'vue'
import { routes } from '@/router'
import router from '@/router'
import { useRoute } from 'vue-router'

const cRouter = useRoute()
const menu = ref(routes.filter(v => !v.meta || !v.meta.isHidden)[0].children)
const currentRoute = computed(() => cRouter.path)

const handleSelect = (key) => {
  router.push({ path: key })
}
</script>

<style lang="scss">
.el-menu {
  width: var(--itsop-menu-width);
}
</style>