import request from '@/utils/request'

export function userData(data) {
  return request({
    url: '/auth/validate',
    method: 'post',
    data
  })
}

export function login(data) {
  return request({
    url: '/auth/login',
    method: 'POST',
    data
  })
}

export function logout(data) {
  return request({
    url: '/auth/logout',
    method: 'POST',
    data
  })
}

export function linktotp(data) {
  return request({
    url: '/user/linktotp',
    method: 'POST',
    data
  })
}

export function unlinktotp(data) {
  return request({
    url: '/user/unlinktotp',
    method: 'POST',
    data
  })
}

// 导出账号
export function exportAccount(data) {
  return request({
    responseType: 'blob',
    url: '/backup/export',
    method: 'post',
    data
  })
}
export function exportCurrentAccount(data) {
  return request({
    responseType: 'blob',
    url: '/backup/current_page_export',
    method: 'post',
    data
  })
}

// 统计
export function statis_backup(data) {
  return request({
    url: '/statis/backup',
    method: 'post',
    data
  })
}