<template>
  <div class="flex h-dvh overflow-x-hidden">
    <menu-com />
    <div class="flex-1 flex flex-col">
      <header>
        <header-com />
        <tag-com />
      </header>
      <div class="main-container m-4 flex-1">
        <router-view v-slot="{ Component }">
          <keep-alive :include="cachedTags">
            <component :is="Component" :key="route.fullPath" />
          </keep-alive>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderCom from '@/components/header/index.vue'
import TagCom from '@/components/tag/index.vue'
import MenuCom from '@/components/menu/index.vue'
import { useTagsStore } from '@/store/tags'
import { useRoute } from 'vue-router'
import { computed } from 'vue'

export default {
  name: 'Layout',
  components: {
    HeaderCom,
    TagCom,
    MenuCom
  },
  computed: {
    cachedTags() {
      const store = useTagsStore()
      return store.cachedTags ? store.cachedTags : []
    }
  },
  setup() {
    const route = useRoute()
    const currentRoute = computed(() => route.path)
    const routeName = computed(() => route.name)
    console.log(route)
    return {
      currentRoute,
      routeName,
      route
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  position: absolute;
  top: 0;
  width: calc(100% - var(--itsop-menu-width));
  background: #fff;
  height: var(--itsop-header-sum-height);
  z-index: 100;
  box-sizing: border-box;
}
.main-container {
  margin-top: calc(var(--itsop-header-sum-height) + 16px);
  overflow-y: auto;
}
</style>
