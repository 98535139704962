import axios from 'axios'
import { ElMessage } from 'element-plus'
import router from '@/router'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
})
const unFormattedUrl = [
  '/backup/export',
  '/backup/current_page_export'
]
service.interceptors.request.use(config => {
  if (!localStorage.getItem('token') && config.url !== '/auth/login') {
    router.push('/login')
    return Promise.reject(new Error('登录失效'))
  }
  config.headers.token = localStorage.getItem('token')
  return config
})

service.interceptors.response.use(response => {
  if (response.data.code && response.data.code !== 200) {
    if (response.data.code === 499) {
      router.replace('/login')
    }
    ElMessage.error(response.data.msg)

    return Promise.reject(response.data)
  }
  if (!response.data.code && unFormattedUrl.includes(response.config.url)) {
    return response.data
  }
  return response.data.data
}, error => {
  if (error.response.status === 499) {
    router.push('/login')
  } else if (error.message) {
    ElMessage.error(error.message)
  }
  return Promise.reject(error.response.data)
})

export default service